<template>
	<div>
		<div class="product-main">
			<div class="title-img">
				<div class="title-img-wapper">
					<h2>人人都享有精准的优质健康服务​</h2>
				</div>
			</div>
			<div class="main1">
				<h1>医用红外检查设备</h1>
				<p class="main-desc">主要产品为MTI系列数字式医用红外热像仪，是集医学技术、红外影像技术、电子技术和计算机图像处理技术于一体的高科技产品</p>
				<div class="content">
					<span>注册证号：渝械注准20192060131</span>
					<div class="equas">
						<div class="equa" v-for="item,index in datas" :key="index">
							<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/"+item.pictures)'/>

							<div class="name">
								{{item.name}}
							</div>
							<div class="text">
								<h5>产品特点:</h5>
								<p v-for="t,i in item.tedian">
									{{t}}
								</p>
								<h5>应用范围:</h5>
								<p v-for="w,j in item.fanwei">
									{{w}}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h5>详细产品参数，欢迎来电咨询！</h5>
			<div class="phone">
				<p>
					<i class="el-icon-phone-outline"></i>
					全国统一客服热线
				</p>
				<p>400-083-0817</p>
			</div>
			<div class="main2">
				<h1>专业医疗专科辅助诊断解决方案</h1>
				<p class="main-desc">为医疗机构的疼痛科、中医科、专业体检机构提供MTI系列数字式医用红外热像仪和读图评估软件的解决方案</p>
				<div class="content">
					<p class="title2">方案优势</p>
					<div class="youshi">
						<div>
							<i class="iconfont icon-artstation"></i>
							<p>安全100%</p>
							<span>0 介入、0 损伤、0 辐射</span>
						</div>
						<div>
							<i class="iconfont icon-codiepie"></i>
							<p>超前预警</p>
							<span>重大疾病的提前风险预警</span>
						</div>
						<div>
							<i class="iconfont icon-chrome"></i>
							<p>全面监测</p>
							<span>覆盖全身七大系统和器官</span>
						</div>
						<div>
							<i class="iconfont icon-buromobelexperte"></i>
							<p>快捷简单</p>
							<span>轻松一照，快速采集</span>
						</div>
					</div>
					<p class="title2">应用领域</p>
					<div class="lingyu">
						<div>
							<div>
								<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/健康普查.jpg")'/>
								<p>健康普查</p>
								<span>亚健康人群状态评估：中风、心梗、肿瘤等重大疾病的早期预警。</span>
							</div>
							<div>
								<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/中医诊断.jpg")'/>
								<p>中医</p>
								<span>进一步完善了中医的诊疗理论体系，使中医诊疗客观化、数字化、可视化；准确辅助中医体质分型及复合体质辨识。</span>
							</div>
							<div>
								<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/疼痛.jpg")'/>
								<p>疼痛</p>
								<span>判断软组织疼痛（颈肩腰腿痛）、急慢性炎症、神经传导障碍及疑难杂症的部位、性质、范围、程度。</span>
							</div>
						</div>
						<div>
							<div>
								<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/肿瘤.jpg")'/>
								<p>肿瘤</p>
								<span>临床常见的肿瘤早期筛选、早期发现及疗效评估。</span>
							</div>
							<div>
								<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/周围血管.jpg")'/>
								<p>周围血管</p>
								<span>监测血管供血功能状态；评估血管功能；判断血管功能障碍主导因素。</span>
							</div>
							<div>
								<img :src='require("@/assets/picture/加载中.gif")' class="lazy" :data-src='require("@/assets/picture/其他.jpg")'/>
								<p>其他</p>
								<span>断肢再植手术成活检测；体育运动监测；康复疗效跟踪及医学教学。</span>
							</div>
						</div>
					</div>
					<p class="title2">专业软件</p>
					<div class="ruanjian">
						<p>全新升级软件，在中医、疼痛、测温等应用场景更具专业性，基于人工智能技术的加持，辅助医生更精准更高效的分析红外热图</p>
						<p>软件专利登记号：</p>
						<div>
							<span>2016SR089581</span>
							<span>2018SR033467</span>
							<span>2020SR0028313</span>
							<span>2020SR0194122</span>
						</div>
						<ul>
							<li>根据红外热图，辅助定位并得出偏颇体质·经络、穴位定位，辅助运算寒热</li>
							<li>全新适合中医元素的界面与报告</li>
							<li>使用操作流畅，按日期、检查项目、科室等管理数据</li>
							<li>基于人工智能(AI)技术，辅助或提示可能遗漏的红外特征·基于大数据辅助在体检、疼痛、治未病等领域预警</li>
							<li>红外影像智能评估系统，秒级评估，辅助诊疗</li>
							<li>全开放式模块设计，用户可按需购买模块</li>
							<li>支持PACS系统</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="main3">
				<h1>基层医疗健康行业赋能解决方案</h1>
				<div class="content">
					<p class="title2">方案概述</p>
					<div class="gaishu">
						<el-image :src='require("@/assets/picture/红外.png")' lazy />
						<p>
							以基于医用红外技术的智能诊断设备基于国内知名医学专业领域专家经验学习的AI辅助诊断平台，为基层诊所提供全科健康评估、疼痛、中医及康复等专科辅助诊断能力，对接各专业领域的全国知名专家进行干预治疗技术的培训及疑难病症的远程会诊服务，推进基层医疗专科能力的建设，全面提升诊断和治疗水平，在社会与经济发展方面取得双向收益。
						</p>
					</div>
					<p class="title2">优势特色</p>
					<div class="tese">
						<div>
							<div>
								<i class="iconfont icon-hospital"></i>
								<p>医用红外检查设备</p>
								<span>拥有医疗器械证的自主知识产权医用红外检查设备</span>
							</div>
							<div>
								<i class="iconfont icon-atlassian"></i>
								<p>人工智能高效精准读图</p>
								<span>基于人工智能、大数据等前沿技术的人工智能读图师，便捷、精准、高效的辅助医生进行诊断评估</span>
							</div>
							<div>
								<i class="iconfont icon-tree"></i>
								<p>绿色安全检测</p>
								<span>利用远红外技术对人体温度的采集，无辐射、无创伤，安全可靠</span>
							</div>
						</div>
						<div>
							<div>
								<i class="iconfont icon-audible"></i>
								<p>精准指症和干预</p>
								<span>一次成像生成全面健康评估报告，根据存在的问题智能推荐干预方案</span>
							</div>
							<div>
								<i class="iconfont icon-monero"></i>
								<p>多用途，可扩展</p>
								<span>同一设备既可做健康评估，还可以做复杂人群下的体温监测；支持多种接入方式，用户、结论等数据可开放共享</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const myEquiData = [
		{
			name: 'MTI-EX PRO-A/B 专业型',
			pictures: 'product/MTI-EX-PRO-AB专业型.jpg',
			tedian: [
				'热平衡全封闭检查舱',
				'检查精度高',
			],
			fanwei: [
				'医疗科研机构、大型三甲医院'
			]
		},
		{
			name: 'MTI-Standard-A/B 通用型',
			pictures: 'product/MTI-Standard-B-通用型.jpg',
			tedian: [
				'半仓体设计',
				'提高成像质量和稳定性',
			],
			fanwei: [
				'二级以上医院，体检中心'
			]
		},
		{
			name: 'MTI-Economy-A/B 普及型',
			pictures: 'product/MTI-Economy-B-普及B型.jpg',
			tedian: [
				'中西医融合',
				'人工智能解读热像图',
			],
			fanwei: [
				'基层及社区医疗',
				'早期健康筛查的最佳工具'
			]
		},
		{
			name: 'MTI-X7 Pro-A/B 疼痛型',
			pictures: 'product/MTI-X7-Pro-B疼痛型.jpg',
			tedian: [
				'高续航锂电',
				'可移动三维多角度探测器',
			],
			fanwei: [
				'各级医院疼痛科、康复理疗科'
			]
		},
		{
			name: 'MTI-Economy-C 便携式',
			pictures: 'product/MTI-Economy-C-便携式.jpg',
			tedian: [
				'既可用做全身健康检测',
				'又能在突发卫生事件中做无接触式应急体温检测',
			],
			fanwei: [
				'基层医疗/乡村健康普查',
				'防疫期间出入口体温检测'
			]
		},
		{
			name: 'MTI-Economy-D 小胖哥',
			pictures: 'product/MTI-Economy-D-小胖哥.jpg',
			tedian: [
				'设计感强，环境匹配度高',
				'提升受检者体验',
			],
			fanwei: [
				'企业员工健康检测'
			]
		},
		{
			name: 'MTI-Economy-X 小管家',
			pictures: 'product/小管家（官网）.jpg',
			tedian: [
				'性价比高，便捷可移动'
			],
			fanwei: [
				'基层医疗机构，如卫生院、诊所等机构'
			]
		},
	]

	export default {
		name: 'Product',
		mounted() {
			this.datas = myEquiData
		},
		data() {
			return {
				datas: [],
			}
		}
	}
</script>

<style lang="scss">
	@import './index.scss';
	@import '~@/styles/public.scss';
</style>
